import { useAuthBuilder } from '~/stores/auth-builder'
import { storeToRefs } from 'pinia'

export async function setupCustomer() {
  const authBuilderStore = useAuthBuilder()
  const { userToken } = storeToRefs(authBuilderStore)
  try {
    return await $fetch(
      'https://client-api.tonos.gjirafa.tech/customer/setup',
      {
        method: 'POST',
        headers: {
          appId: window.tonos_integration_application_id,
          Authorization: `Bearer ${userToken.value}`,
        },
      }
    )
  } catch (error) {
    throw error
  }
}

export async function createPaymentIntent(amount, currency) {
  try {
    return await $fetch('/api/create-payment-intent', {
      method: 'POST',
      body: {
        amount,
        currency,
      },
    })
  } catch (error) {
    throw error
  }
}

export async function setupCard(papId) {
  const authBuilderStore = useAuthBuilder()
  const { userToken } = storeToRefs(authBuilderStore)
  try {
    return await $fetch(
      'https://client-api.tonos.gjirafa.tech/stripe/card-setup',
      {
        method: 'POST',
        headers: {
          appId: window.tonos_integration_application_id,
          Authorization: `Bearer ${userToken.value}`,
        },
        body: { papId: papId },
      }
    )
  } catch (error) {
    throw error
  }
}

export async function purchasePap(model) {
  let data = new purchasePapModel()
  data.fromData(model) // populates the instance "data"
  const authBuilderStore = useAuthBuilder()
  const { userToken } = storeToRefs(authBuilderStore)
  try {
    return await $fetch(
      'https://client-api.tonos.gjirafa.tech/pap/purchase-pap',
      {
        method: 'POST',
        headers: {
          appId: window.tonos_integration_application_id,
          Authorization: `Bearer ${userToken.value}`,
        },
        body: { ...data },
      }
    )
  } catch (error) {
    throw error
  }
}

export async function fetchOfferByCampaign(
  campaignPublicId,
  offerPublicId,
  includeUserToken = true
) {
  if (!campaignPublicId) {
    console.warn('No campaignPublicId provided, skipping API call')
    return null
  }

  const authBuilderStore = useAuthBuilder()
  const { userToken } = storeToRefs(authBuilderStore)

  const url = `https://client-api.tonos.gjirafa.tech/offer/by-campaign?campaignPublicId=${campaignPublicId}`

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        appId: window.tonos_integration_application_id,
        Authorization: includeUserToken ? `Bearer ${userToken.value}` : null,
      },
    })

    if (!response.ok) {
      throw new Error(`Failed to fetch offers: ${response.statusText}`)
    }

    const responseData = await response.json()
    const offersArray = responseData.data || []

    // Find the offer matching the provided offerPublicId
    const selectedOffer = offersArray.find(
      (offer) => offer.ot === offerPublicId
    )

    if (selectedOffer) {
      const firstPap = {
        ...selectedOffer.pap[0],
        offerName: selectedOffer.on,
        cid: campaignPublicId,
        oi: selectedOffer.oi,
        pk: '',
        tid: selectedOffer.id,
        offerId: offerPublicId,
      }

      const stripePublicKey = await getStripePublicKey(firstPap.oi)
      firstPap.pk = stripePublicKey

      return {
        offerId: selectedOffer.id,
        firstPap,
        selectedOffer,
      }
    } else {
      console.warn('No offer found with id:', offerPublicId)
      return null
    }
  } catch (error) {
    console.error('Error fetching offers:', error)
    throw error
  }
}

export async function getStripePublicKey(oId) {
  try {
    const res = await $fetch(
      `https://client-api.tonos.gjirafa.tech/checkout?oi=${oId}`,
      {
        method: 'GET',
        headers: {
          appId: window.tonos_integration_application_id,
        },
      }
    )
    return res.data.ps.pk
  } catch (error) {
    throw error
  }
}

class purchasePapModel {
  constructor() {
    this.tid = ''
    this.papid = ''
    this.price = null
    this.discountedPrice = null
    this.currentDiscount = 0
    this.deviceData = null
    this.cid = ''
    this.bd = {
      pid: null,
      bcid: null,
      ba: '',
      scid: null,
      sa: null,
    }
    this.vat = 0
    this.po = 1
    this.nameOnCard = ''
    this.returnUrl = null
    this.biskoId = ''
    this.gpd = null
    this.cc = []
    this.exid = null
    this.ssi = ''
  }

  fromData(data) {
    this.tid = data.tid
    this.papid = data.papid
    this.price = data.price
    this.discountedPrice = data.discountedPrice || 0
    this.currentDiscount = data.currentDiscount
    this.deviceData = data.deviceData
    this.cid = data.cid
    this.bd = data.bd
    this.vat = data.vat
    this.po = data.po
    this.nameOnCard = data.nameOnCard
    this.returnUrl = data.returnUrl || null
    if (typeof biskota != 'undefined' && biskota) {
      this.biskoId = biskota.getBiskoId()
    }
    this.gpd = data.gpd || null
    this.cc = data.cc || []
    this.exid = data.exid
    this.ssi = data.ssi
  }
}

export default purchasePapModel
