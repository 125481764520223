export function calculateSubscriptionDate(
  timeUnitId,
  timeUnitValue,
  type,
  isRecurring
) {
  if (!isRecurring && type === 1 && timeUnitValue === 0) {
    return null
  }

  if (!isRecurring && type === 2 && timeUnitValue === 0) {
    return null
  }

  const now = new Date()

  switch (timeUnitId) {
    case 7: // Years
      now.setFullYear(now.getFullYear() + timeUnitValue)
      break
    case 6: // Months
      now.setMonth(now.getMonth() + timeUnitValue)
      break
    case 5: // Weeks
      now.setDate(now.getDate() + timeUnitValue * 7)
      break
    case 4: // Days
      now.setDate(now.getDate() + timeUnitValue)
      break
    case 3: // Hours
      now.setHours(now.getHours() + timeUnitValue)
      break
    case 2: // Minutes
      now.setMinutes(now.getMinutes() + timeUnitValue)
      break
    case 1: // Seconds
      now.setSeconds(now.getSeconds() + timeUnitValue)
      break
    default:
      console.warn('Unknown timeUnitId:', timeUnitId)
      return 'Invalid time period'
  }

  return now.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  })
}

export function subscriptionPeriod(type, val, isRecurring) {
  if (!isRecurring && val === 0) {
    return 'One Time Payment'
  }
  switch (type) {
    case 1:
      return 'SECOND'
    case 2:
      return 'MINUTE'
    case 3:
      return 'HOUR'
    case 4:
      return 'DAY'
    case 5:
      return 'WEEK'
    case 6:
      return 'MONTH'
    case 7:
      return 'YEAR'
    default:
      console.warn('Unknown timeUnitId:', type)
      return 'Invalid time period'
  }
}
